import { makeStyles } from '@mui/styles'

import GitHubIcon from '@mui/icons-material/GitHub'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3),
    },

    name: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
    },

    icons: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(16),
    },

    icon: {
      margin: theme.spacing(1),
    },

    button: {
      marginBottom: theme.spacing(2),
    },
  }),
  {
    name: 'Homepage',
  }
)

const links = [
  {
    text: 'Geolocation React Hook Demo',
    href: 'https://geo.masao.io/',
  },
  {
    text: 'Mobile Chrome Browser Motion Sensor Demo (in progress)',
    href: 'https://sensors.masao.io/google.html',
  },
  {
    text: 'HackerRank Candies Solution and Table Visualization',
    href: 'https://candies.masao.io',
  },
  {
    text: 'Knapsack Dynamic Programming Visualizer',
    href: 'https://knapsack.masao.io',
  },
]

const social = [
  {
    href: 'https://github.com/masaok',
    icon: GitHubIcon,
  },
  {
    href: 'https://linkedin.com/in/masaok',
    icon: LinkedInIcon,
  },
  {
    href: 'https://instagram.com/masaocodes/',
    icon: InstagramIcon,
  },
]

const Homepage = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <Typography variant="h2">Masao Kitamura</Typography>
      </div>

      {/* Solution Visualizer Links */}
      {links.map((item, index) => {
        return (
          <Grow in={true} appear {...{ timeout: (index + 1) * 1000 }}>
            <div className={classes.button}>
              <Button variant="contained" target="_blank" href={item.href}>
                {item.text}
              </Button>
            </div>
          </Grow>
        )
      })}

      {/* Social Icons */}
      <div className={classes.icons}>
        {social.map((item, index) => {
          const Icon = item.icon
          return (
            <Fade in={true} appear {...{ timeout: (index + 1) * 1000 }}>
              {/* <Fade in={true} appear {...{ timeout: 1000 }}> */}
              <div className={classes.icon}>
                <IconButton target="_blank" href={item.href}>
                  <Icon />
                </IconButton>
              </div>
            </Fade>
          )
        })}
      </div>
    </div>
  )
}

export default Homepage
